<template>
  <a-modal
    width="800px"
    v-model="isShow"
    centered
    :mask-closable="false"
    title="权限客户"
  >
    <template slot="footer">
      <a-button class="ant-btn-primary" @click="closeModal">
        关闭
      </a-button>
    </template>

    <search-api-interface-associated-agents @submit="submitSearch" />

    <a-table
      size="middle"
      :scroll="{ y: 350 }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    />

    <a-row class="count-tips">
      共 {{ data.length }} 条记录
    </a-row>
  </a-modal>
</template>

<script>
import { findApiInterfaceAgents } from '@/api/api_interface'
import SearchApiInterfaceAssociatedAgents from '@/views/api_interfaces/associated_agents/Search'

export default {
  name: 'ApiInterfaceAssociatedAgentsList',
  components: {
    SearchApiInterfaceAssociatedAgents
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: [],
      loading: true,
      query: {
        page: 1,
        per_page: 100000 // TODO 分页
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '客户名称',
          dataIndex: 'name'
        },
        {
          title: '开通时间',
          width: 200,
          dataIndex: 'opened_at'
        },
        {
          title: '操作人',
          width: 200,
          dataIndex: 'operator_name'
        }
      ]
    },

    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    closeModal() {
      this.isShow = false
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findApiInterfaceAgents(this.id, this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.count-tips {
  padding-top: 20px;
}
</style>
